export default [
  {
    title: '所属产品领域编码',
    width: 200,
    property: 'therapeutic_area_code',
    search: 'input',
    sort: false,
    edit: false,
    default: false,
  },
  {
    title: '所属产品领域名称',
    width: 200,
    property: 'therapeutic_area_name',
    search: 'select',
    sort: false,
    edit: false,
    default: true,
  },
  {
    title: '产品线/产品类型编码',
    width: 200,
    property: 'productline_code',
    search: 'input',
    sort: false,
    edit: false,
    default: false,
  },
  {
    title: '产品线/产品类型名称',
    width: 200,
    property: 'productline_name',
    search: 'select',
    sort: false,
    edit: false,
    default: true,
  },
  {
    title: '产品编码',
    width: 200,
    property: 'product_code',
    search: 'input',
    sort: false,
    edit: false,
    default: false,
  },
  {
    title: '产品名称',
    width: 200,
    property: 'product_name',
    search: 'input',
    sort: false,
    edit: false,
    default: true,
    fixed: true,
  },
  {
    title: '产品英文名称',
    width: 200,
    property: 'product_eng_name',
    search: 'input',
    sort: false,
    edit: false,
    default: false,
  },
  {
    title: '商品名',
    width: 200,
    property: 'trade_name',
    search: 'input',
    sort: false,
    edit: true,
    default: true,
  },
  {
    title: '商品英文名',
    width: 200,
    property: 'trade_eng_name',
    search: 'input',
    sort: false,
    edit: true,
    default: false,
  },
  {
    title: '药品通用名',
    width: 200,
    property: 'common_name',
    search: 'input',
    sort: false,
    edit: true,
    default: true,
  },
  {
    title: '药品英文通用名',
    width: 200,
    property: 'common_eng_name',
    search: 'input',
    sort: false,
    edit: true,
    default: false,
  },
  {
    title: '药品类型',
    width: 200,
    property: 'category',
    search: 'select',
    sort: false,
    edit: true,
    default: true,
  },
  {
    title: '成分',
    width: 200,
    property: 'ingredients',
    search: 'input',
    sort: false,
    edit: true,
    default: false,
  },
  {
    title: '是否处方药',
    width: 200,
    property: 'is_RX',
    search: 'select',
    sort: false,
    edit: true,
    default: true,
  },
  //   {
  //     title: '是否已删除',
  //     width: 200,
  //     property: 'is_deleted',
  //     search: 'input',
  //     sort: false,
  //     edit: false,
  //     default: false,
  //   },
  {
    title: '创建人',
    width: 200,
    property: 'create_user',
    search: 'input',
    sort: false,
    edit: false,
    default: true,
  },
  {
    title: '创建时间',
    width: 200,
    property: 'create_time',
    search: 'date',
    sort: true,
    edit: false,
    default: true,
    format: true,
  },
  {
    title: '更新人',
    width: 200,
    property: 'update_user',
    search: 'input',
    sort: false,
    edit: false,
    default: true,
  },
  {
    title: '更新时间',
    width: 200,
    property: 'update_time',
    search: 'date',
    sort: true,
    edit: false,
    default: true,
    format: true,
  },
  {
    title: '版本',
    width: 200,
    property: 'record_version',
    search: 'input',
    sort: false,
    edit: false,
    default: false,
  },
  {
    title: '数据来源',
    width: 200,
    property: 'source',
    search: 'select',
    sort: false,
    edit: false,
    default: false,
  },
  {
    title: '备注评论',
    width: 200,
    property: 'comment',
    search: 'input',
    sort: false,
    edit: true,
    default: false,
  },
  {
    title: '是否存疑',
    width: 200,
    property: 'in_doubt',
    search: 'select',
    sort: false,
    edit: false,
    default: false,
  },
  {
    title: '存疑原因',
    width: 200,
    property: 'doubt_reason',
    search: 'input',
    sort: false,
    edit: false,
    default: false,
  },
  {
    title: '质量负责人',
    width: 200,
    property: 'qa_persons',
    search: 'input',
    sort: false,
    edit: false,
    default: false,
  },
];

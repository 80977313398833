export default [
  {
    title: '剂型分类',
    property: 'administration',
    value: [
      {
        code: '1',
        label: '表皮',
      },
      {
        code: '2',
        label: '口服',
      },
      {
        code: '3',
        label: '吸入',
      },
      {
        code: '4',
        label: '阴道',
      },
      {
        code: '5',
        label: '注射',
      },
      {
        code: '9',
        label: '其他',
      },
    ],
  },
  {
    title: '剂型',
    property: 'prepared_form',
    value: [
      {
        code: '101',
        label: '搽剂',
      },
      {
        code: '102',
        label: '气雾剂',
      },
      {
        code: '103',
        label: '乳膏剂',
      },
      {
        code: '104',
        label: '涂剂',
      },
      {
        code: '105',
        label: '外用酊剂',
      },
      {
        code: '106',
        label: '醑剂',
      },
      {
        code: '201',
        label: '肠溶胶囊',
      },
      {
        code: '202',
        label: '肠溶片',
      },
      {
        code: '203',
        label: '大蜜丸',
      },
      {
        code: '204',
        label: '含片',
      },
      {
        code: '205',
        label: '合剂',
      },
      {
        code: '206',
        label: '缓释片',
      },
      {
        code: '207',
        label: '胶囊剂',
      },
      {
        code: '208',
        label: '咀嚼片',
      },
      {
        code: '209',
        label: '颗粒剂',
      },
      {
        code: '210',
        label: '控释片',
      },
      {
        code: '211',
        label: '口服酊剂',
      },
      {
        code: '212',
        label: '口服溶液剂',
      },
      {
        code: '213',
        label: '浓缩水丸',
      },
      {
        code: '214',
        label: '浓缩丸',
      },
      {
        code: '215',
        label: '泡腾颗粒',
      },
      {
        code: '216',
        label: '泡腾片',
      },
      {
        code: '217',
        label: '片剂',
      },
      {
        code: '218',
        label: '软胶囊',
      },
      {
        code: '219',
        label: '水蜜丸',
      },
      {
        code: '220',
        label: '水丸',
      },
      {
        code: '221',
        label: '糖浆剂',
      },
      {
        code: '222',
        label: '丸剂',
      },
      {
        code: '223',
        label: '小蜜丸',
      },
      {
        code: '501',
        label: '注射液',
      },
      {
        code: '502',
        label: '注射用无菌粉末',
      },
      {
        code: '401',
        label: '阴道片',
      },
      {
        code: '301',
        label: '吸入气雾剂',
      },
      {
        code: '901',
        label: '其他',
      },
    ],
  },
  {
    title: '单位',
    property: 'unit',
    value: [
      {
        code: '1',
        label: '盒',
      },
      {
        code: '2',
        label: '支',
      },
      {
        code: '3',
        label: '瓶',
      },
      {
        code: '4',
        label: '条',
      },
      {
        code: '99',
        label: '其他',
      },
    ],
  },
  {
    title: '销售状态',
    property: 'sales_status',
    value: [
      {
        code: '1',
        label: '在售',
      },
      {
        code: '2',
        label: '停售',
      },
    ],
  },
  {
    title: '是否医保',
    property: 'is_medical_insurance',
    value: [
      {
        label: '不适用/未知',
        code: '0',
      },
      {
        label: '是',
        code: '1',
      },
      {
        label: '否',
        code: '2',
      },
    ],
  },
  {
    title: '数据来源',
    property: 'source',
    value: [
      { label: '默认', code: '0' },
      { label: '腾讯地图', code: '4' },
      { label: '卫健委', code: '7' },
      { label: '百度地图', code: '5' },
      { label: '高德地图', code: '3' },
      { label: '天眼查', code: '2' },
      { label: '企查查', code: '1' },
      { label: '医院', code: '6' },
      { label: 'MedMD管理端创建', code: '8' },
    ],
  },
  {
    title: '是否存疑',
    property: 'in_doubt',
    value: [
      { code: '1', label: '存疑' },
      { code: '2', label: '不存疑' },
    ],
  },
];

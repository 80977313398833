export default [
  {
    title: '所属产品领域名称',
    property: 'therapeutic_area_name',
    value: [
      {
        code: 'A',
        label: '消化道及代谢',
      },
      {
        code: 'B',
        label: '血液和造血器官',
      },
      {
        code: 'C',
        label: '心血管系统',
      },
      {
        code: 'D',
        label: '皮肤病用药',
      },
      {
        code: 'G',
        label: '生殖泌尿系统和性激素',
      },
      {
        code: 'H',
        label: '非性激素和胰岛素类的激素类系统用药',
      },
      {
        code: 'J',
        label: '系统用抗感染药',
      },
      {
        code: 'L',
        label: '抗肿瘤药和免疫机能调节药',
      },
      {
        code: 'M',
        label: '肌肉-骨骼系统',
      },
      {
        code: 'N',
        label: '神经系统',
      },
      {
        code: 'P',
        label: '抗寄生虫药、杀虫药和驱虫药',
      },
      {
        code: 'R',
        label: '呼吸系统',
      },
      {
        code: 'S',
        label: '感觉器官',
      },
      {
        code: 'V',
        label: '杂类',
      },
      {
        code: 'Other',
        label: '其它',
      },
    ],
  },
  {
    title: '产品线/产品类型名称',
    property: 'productline_name',
    value: [
      {
        code: 'Unknown',
        label: '未知',
      },
      {
        code: 'default',
        label: '默认',
      },
    ],
  },
  {
    title: '药品类型',
    property: 'category',
    value: [
      {
        code: 'CHE',
        label: '化学药品',
      },
      {
        code: 'BIO',
        label: '生物制品',
      },
      {
        code: 'CHI',
        label: '中成药',
      },
      {
        code: 'Other',
        label: '其他',
      },
    ],
  },

  {
    title: '是否处方药',
    property: 'is_RX',
    value: [
      {
        code: 'RX',
        label: '处方药',
      },
      {
        code: 'OTC',
        label: '非处方药',
      },
      {
        code: 'Other',
        label: '其他',
      },
    ],
  },

  {
    title: '数据来源',
    property: 'source',
    value: [
      { label: '默认', code: '0' },
      { label: '腾讯地图', code: '4' },
      { label: '卫健委', code: '7' },
      { label: '百度地图', code: '5' },
      { label: '高德地图', code: '3' },
      { label: '天眼查', code: '2' },
      { label: '企查查', code: '1' },
      { label: '医院', code: '6' },
      { label: 'MedMD管理端创建', code: '8' },
    ],
  },
  {
    title: '是否存疑',
    property: 'in_doubt',
    value: [
      { code: '1', label: '存疑' },
      { code: '2', label: '不存疑' },
    ],
  },
];

<!--  -->
<template>
  <div class="container">
    <p class="title">{{ this.$route.query.type === 'spu' ? '药品' : '药品(SKU)' }}</p>
    <div class="datalist-title-action">
      <div class="action-left">
        <el-button v-auto-unfocus type="primary" class="data-list-createBtn" @click="handleAddData">
          新建数据
        </el-button>
        <!-- <el-button
          v-auto-unfocus
          :disabled="!showEditBtn"
          :class="{ 'data-list-exportBtn': showEditBtn, 'data-list-eb': !showEditBtn }"
        >
          编辑
        </el-button>
        <el-button v-auto-unfocus class="data-list-importBtn">导入</el-button>
        <el-button v-auto-unfocus class="data-list-exportBtn" @click="handleExport">导出</el-button> -->
      </div>

      <div class="action-right">
        <el-button icon="el-icon-refresh-right" @click="getList"></el-button>
        <el-button icon="el-icon-setting" @click="handleSetConfig"></el-button>
      </div>
    </div>

    <div v-if="filterList.length > 0" class="datalist-title-filter">
      <div v-for="(item, index) in filterList" :key="index" class="datalist-title-filter-item">
        {{ getLabel(item.name) }}: {{ item.label }}
        <i class="el-icon-close" @click="deleteFilterItem(item.name)"></i>
      </div>
      <el-button
        v-if="filterList.length"
        class="datalist-title-button"
        type="text"
        @click="deleteAllFilter"
      >
        重置
      </el-button>
    </div>

    <div class="data-table" v-show="!requestData">
      <eyao-table
        ref="table"
        :table-data="tableData"
        :total-num="total"
        :select-page-size="10"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
        <!-- <el-table-column type="selection" :reserve-selection="true" /> -->
        <el-table-column
          v-for="column in configList"
          :key="`${$route.query.type}-${column.property}`"
          :prop="column.property"
          :column-key="column.property"
          :min-width="column.width"
          :label="column.title"
          :sortable="column.sort"
          :fixed="column.fixed"
        >
          <template slot="header" slot-scope="scope">
            <eyao-table-search-header
              v-if="column.search === 'input' || column.search === 'select'"
              :form-type="column.search"
              :property="column.property"
              :title="column.title"
              :scope="scope"
              :options="valueOpts[column.property]"
              @search="search"
            ></eyao-table-search-header>
            <template v-else>{{ column.title }}</template>
          </template>

          <template slot-scope="scope">
            <eyao-table-edit-cell
              v-if="column.edit"
              :form-type="column.search"
              :property="column.property"
              :title="column.title"
              :scope="scope"
              :options="valueOpts[column.property]"
              @edit="edit"
            ></eyao-table-edit-cell>
            <!-- id字段标蓝 点击后跳转 -->
            <template
              v-else-if="column.property === 'product_name' || column.property === 'sku_name'"
            >
              <div
                @click="gotoDetail(scope.row.id, column.property, scope.row)"
                :style="{ color: '#0069FA', cursor: 'pointer' }"
              >
                {{ scope.row[column.property] }}
              </div>
            </template>
            <!-- 涉及时间字段，format值 -->
            <template v-else-if="column.format">
              <template>{{ scope.row[column.property] | getFormatTime }}</template>
            </template>
            <template v-else>{{ scope.row[column.property] }}</template>
          </template>
        </el-table-column>

        <!-- 操作列 -->
        <el-table-column v-if="role !== 3" min-width="130" fixed="right" label="操作">
          <div slot-scope="scope" class="data-list-option">
            <div @click="gotoEdit(scope.row)">编辑</div>
            <div @click="deleteData(scope.row)">删除</div>
          </div>
        </el-table-column>

        <template v-slot:empty>
          <div class="no_data" v-if="!requestData && !tableData.length">
            <img
              src="https://baike-med-1256891581.file.myqcloud.com/drugs_admin/20111202/6fd06465-d653-4f86-963e-cb5196e49caf.png"
              alt="未查询到相关数据"
            />
            未查询到相关数据
          </div>
        </template>
      </eyao-table>
    </div>
    <template v-if="requestData">
      <div class="listLoading">
        <img src="@/assets/loading.gif" alt="loading" />
      </div>
    </template>

    <!-- 删除弹窗 -->
    <el-dialog
      title="确认删除吗"
      :visible.sync="showDeleteDialog"
      width="400px"
      class="deleteDataModel"
    >
      <span slot="title" :style="{ fontSize: '14px', color: '#1C2028' }">
        <i class="el-icon-warning" :style="{ fontSize: '18px', color: '#FA9600' }" />
        确认删除吗
      </span>
      请填写删除原因
      <el-input v-model="deleteReason"></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button class="dialog-footer-cancelBtn" @click="showDeleteDialog = false">
          取 消
        </el-button>
        <el-button type="primary" @click="deleteConfirm">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 设置列表字段model -->
    <el-dialog
      title="设置列表字段"
      :visible.sync="showTransferField"
      width="600px"
      top="30vh"
      class="transfer-field-dialog"
      :before-close="closeTransferField"
    >
      <div class="transfer-field-warp">
        <el-transfer
          ref="fieldTransfer"
          v-model="transferedFields"
          :data="allDataFields"
          :titles="['可选字段', '显示字段']"
          target-order="push"
          @change="handleTransferChange"
          @right-check-change="choosedRightField"
        ></el-transfer>
        <div class="transfer-field-move">
          <el-button :disabled="rightFieldCheck.length === 0" @click="handleFieldUp">
            <i class="el-icon-top"></i>
          </el-button>
          <el-button :disabled="rightFieldCheck.length === 0" @click="handleFieldDown">
            <i class="el-icon-bottom"></i>
          </el-button>
        </div>
        <div class="transfer-field-reset" @click="handleResetTransfer">恢复默认字段</div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button class="dialog-footer-cancelBtn" @click="closeTransferField">取 消</el-button>
        <el-button type="primary" :disabled="!transferedFields.length" @click="comfirmTransfer">
          应 用
        </el-button>
      </div>
    </el-dialog>

    <!-- 数据导出model -->
    <el-dialog
      title="数据导出"
      :visible.sync="showDataExportDialog"
      width="680px"
      top="20vh"
      class="dataExport-warp"
      :before-close="handleCloseDialog"
    >
      <el-form :model="dataExportForm">
        <el-form-item
          v-if="showExportOpt"
          label="导出数据类型"
          label-width="96px"
          :style="{ marginleft: '18px', color: '#667082' }"
        >
          {{ title }}
        </el-form-item>

        <el-form-item
          v-if="showExportOpt"
          label="导出数据数量"
          label-width="96px"
          :style="{ marginleft: '18px', color: '#667082' }"
        >
          <el-select v-model="dataExportForm.quantity" clearable filterable style="width: 90px">
            <el-option
              v-for="item in dataExportForm.options"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
          &nbsp;条
        </el-form-item>

        <el-form-item
          label="选择导出字段"
          label-width="96px"
          :style="{ marginleft: '18px', color: '#667082' }"
        >
          <div class="transfer-field-warp">
            <el-transfer
              ref="exportFieldTransfer"
              v-model="dataExportForm.exportRightFields"
              :data="dataExportForm.allExportFields"
              :titles="['可选字段', '导出字段']"
              target-order="push"
              @change="changeExportField"
              @right-check-change="exportCheckedFields"
            ></el-transfer>

            <div class="transfer-field-move">
              <el-button
                :disabled="dataExportForm.checkedFields.length === 0"
                @click="handleExportFieldUp"
              >
                <i class="el-icon-top"></i>
              </el-button>
              <el-button
                :disabled="dataExportForm.checkedFields.length === 0"
                @click="handleExportFieldDown"
              >
                <i class="el-icon-bottom"></i>
              </el-button>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="dialog-footer-cancelBtn" @click="handleCloseDialog">取 消</el-button>
        <el-button
          type="primary"
          :disabled="dataExportForm.exportRightFields.length === 0"
          @click="comfirmExportData"
        >
          导 出
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import eyaoTable from '@/components/table';
import spuConfig from './config/drug-spu';
import skuConfig from './config/drug-sku';
import spuLabelConfig from './labelConfig/spu';
import skuLabelConfig from './labelConfig/sku';
import {
  SearchMainData,
  GetDictOptionList,
  EditMainDataDetail,
  DeleteMainDataDetail,
} from '@/api/data';
import { dateFormat } from '@/utils/util';
import eyaoTableSearchHeader from '@/components/table-search-header';
import { DICT_TYPE, REQUEST_TYPE } from '@/utils/constant';
import eyaoTableEditCell from '@/components/table-edit-cell';
export default {
  components: {
    eyaoTable,
    eyaoTableSearchHeader,
    eyaoTableEditCell,
  },
  filters: {
    getFormatTime(value) {
      if (value && !value.startsWith('0')) {
        return dateFormat(new Date(value), 'YYYY-MM-dd HH:mm:SS');
      }
      return '';
    },
  },
  data() {
    return {
      deleteDialogTarget: '',
      deleteReason: '',
      showDeleteDialog: false,
      showEditBtn: false,
      tableData: [],
      editData: [], // 存放未格式化的原始数据列表
      total: 0,
      page: 1,
      pageSize: 10,
      configList: [], // 列表配置
      requestData: false, // 列表Loading
      showTransferField: false, // 自定义字段窗口显示开关
      transferedFields: [], // 选择自定义字段
      allDataFields: [], // 可选字段
      rightFieldCheck: [], // 右边选中的字段
      keyOpts: [], // 批量编辑字段配置
      configObj: {},
      valueOpts: {},
      labelConfig: [],
      propertyName: [
        'therapeutic_area_name',
        'productline_name',
        'category',
        'is_RX',
        'source',
        'in_doubt',
        'administration',
        'prepared_form',
        'unit',
        'sales_status',
        'is_medical_insurance',
      ], // 需要转换成label展示的字段
      filterList: [], // 筛选
      filterObject: {},

      selectedData: [], // 选择导出字段
      showDataExportDialog: false, // 导出窗口显示开关
      showExportOpt: false,
      dataExportForm: {
        quantity: 20,
        options: [20, 50, 100, 200],
        allExportFields: [],
        exportRightFields: [],
        checkedFields: [],
      },
      title: '', // 导出类型title
    };
  },
  computed: {
    role() {
      const newRole = JSON.parse(localStorage.getItem('role'));
      // 页面刷新时
      if (newRole) {
        this.$store.commit('user/updateRole', newRole);
      } else {
        return this.$store.state.user.role;
      }

      return newRole;
    },
  },
  watch: {
    $route(to) {
      if (to.name === 'drug-list') {
        this.filterList = [];
        localStorage.removeItem('drug-filter');
        this.getConfig();
        this.getOptions();
        this.getList();
      }
    },
  },
  created() {},
  mounted() {
    this.getConfig();
    this.getOptions();
    this.getList();
  },
  methods: {
    gotoEdit(row) {
      const routerData = this.$router.resolve({
        path: '/dashboard/drug-detail',
        query: { type: 'edit', source: this.$route.query.type, id: row.id },
      });
      window.open(decodeURIComponent(routerData.href), '_blank');
    },
    deleteConfirm() {
      if (this.deleteReason === '') {
        this.$message.error('请填写删除原因');
        return false;
      }
      const params = {
        obj_name: REQUEST_TYPE[this.$route.query.type],
        data_id: this.deleteDialogTarget,
        reason: this.deleteReason,
      };
      DeleteMainDataDetail(params)
        .then(() => {
          this.$message.success('删除成功');
          this.showDeleteDialog = false;
          this.deleteDialogTarget = '';
          this.deleteReason = '';
          this.getList();
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    },
    deleteData(row) {
      console.log(row);
      this.deleteDialogTarget = row.id;
      this.showDeleteDialog = true;
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
    setStorage() {
      const filterData = this.getStorage();
      filterData[this.$route.query.type] = this.filterList;
      localStorage.setItem('drug-filter', JSON.stringify(filterData));
    },
    getStorage() {
      const str = localStorage.getItem('drug-filter');
      return str ? JSON.parse(str) : {};
    },
    search(property, val, formType, label) {
      console.log(property, val, formType, label);
      if (!this.filterList.length) {
        if (formType === 'select') {
          this.filterList.push({
            name: property,
            value: val,
            label,
          });
        } else {
          this.filterList.push({
            name: property,
            value: val,
            label: val,
          });
        }
      } else {
        if (this.filterList.every(item => item.name !== property)) {
          if (formType === 'select') {
            this.filterList.push({
              name: property,
              value: val,
              label,
            });
          } else {
            this.filterList.push({
              name: property,
              value: val,
              label: val,
            });
          }
        } else {
          this.filterList.forEach(item => {
            if (item.name === property) {
              if (formType === 'select') {
                item.value = val;
                item.label = label;
              } else {
                item.value = val;
              }
            }
          });
        }
      }
      console.log(this.filterList);
      this.setStorage();
      this.page = 1;
      this.pageSize = 10;
      this.total = 0;
      this.getList();
    },
    edit(id, property, val, labelList) {
      console.log(111);
      console.log(id, property, val, labelList);
      const beforeEdit = this.editData.filter(ele => ele.id === id)[0];
      const beforeEditLabel = this.tableData.filter(ele => ele.id === id)[0];
      console.log(beforeEdit);
      if (beforeEdit && beforeEditLabel[property] === labelList) {
        this.$message.error('数据未发生改变');
        return;
      }
      const req = {
        obj_name: this.$route.query.type === 'spu' ? 't_product' : 't_product_sku',
        data_id: id,
        content: [
          {
            field_name: property,
            before_val: beforeEdit[property],
            after_val: val,
          },
        ],
        data_confirm: 0,
      };
      EditMainDataDetail(req)
        .then(res => {
          if (res.code === '0') {
            this.$message.success('编辑成功');
            this.getList();
          }
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    },

    // 筛选条件被手动删除时调用table方法更新table内筛选信息
    deleteFilterItem(key) {
      console.log(key);
      this.filterObject[key] = {};
      this.filterList = this.filterList.filter(item => item.name !== key);
      this.setStorage();
      this.$refs.table.clearFilter([key]);
      this.page = 1;
      this.pageSize = 10;
      this.total = 0;
      this.getList();
    },

    deleteAllFilter() {
      localStorage.removeItem('drug-filter');
      this.filterList = [];
      this.filterObject = {};
      this.page = 1;
      this.pageSize = 10;
      this.total = 0;
      this.$refs.table.clearFilter();
      setTimeout(() => {
        this.getList();
      });
    },
    getLabel(property) {
      return this.configObj[property].title;
    },
    // 处理列表中下拉菜单的label值
    dealDataLabel(list) {
      const reg = /^[0-9a-zA-Z]/;
      list.forEach(ele => {
        Object.keys(ele).forEach(key => {
          if (this.propertyName.includes(key)) {
            // console.log(key, ele[key]);
            // 向下拉取数据，会导致之前转化为label的数据消失，是因为转了文字后，这里不能识别。
            // 解决方法：判断是否是数字或者字母开头，纯文字不管（已经转为label）
            if (reg.test(ele[key])) {
              ele[key] = this.getCodeLabel(key, ele[key]);
            }
          }
        });
      });
    },
    getCodeLabel(key, value) {
      //   console.log(key, value);
      const valObj = this.labelConfig.filter(item => item.property === key);
      //   console.log(valObj);
      let valArr = [];
      if (JSON.stringify(valObj) !== '{}') {
        valArr = valObj[0].value;
      }
      let res = '';
      if (valArr.length > 0) {
        const arr = valArr.filter(item => item.code === value);
        if (arr.length > 0) {
          res = arr[0].label;
        }
      }
      return res;
    },
    // 获取下拉选项数据
    getOptions() {
      const optionsApi = [];
      const opts = {};
      // console.log(this.config, 'options---');
      this.configList.forEach(item => {
        if (item.search === 'select') {
          let params = {
            dict_api_name: item.property,
            dict_type: DICT_TYPE[this.$route.query.type],
          };
          if (item.property === 'source') {
            params = {
              dict_api_name: 'source',
              dict_type: '4',
            };
          }
          if (item.property === 'in_doubt') {
            params = {
              dict_api_name: 'in_doubt',
              dict_type: '0',
            };
          }
          if (item.property === 'productline_name') {
            params = {
              dict_api_name: 'productline_code',
              dict_type: DICT_TYPE[this.$route.query.type],
            };
          }
          if (item.property === 'therapeutic_area_name') {
            params = {
              dict_api_name: 'therapeutic_area_code',
              dict_type: DICT_TYPE[this.$route.query.type],
            };
          }
          optionsApi.push(
            GetDictOptionList(params).then(res => {
              opts[item.property] = res.dict_list.map(opt => {
                return {
                  text: opt.label,
                  label: opt.label,
                  value: opt.code,
                };
              });
              return true;
            }),
          );
        }
      });
      Promise.all(optionsApi).then(() => {
        Object.keys(opts).forEach(key => {
          this.$set(this.valueOpts, key, opts[key]);
        });
        console.log(this.valueOpts);
      });
    },
    // 跳转详情
    gotoDetail(id, name, row) {
      console.log(id, name, row);
      if (name === 'sku_name') {
        const routerData = this.$router.resolve({
          path: '/dashboard/drug-detail',
          query: { type: 'preview', source: this.$route.query.type, id },
        });
        window.open(decodeURIComponent(routerData.href), '_blank');
      }
      if (name === 'product_name' && this.$route.query.type === 'spu') {
        const routerData = this.$router.resolve({
          path: '/dashboard/drug-detail',
          query: { type: 'preview', source: this.$route.query.type, id },
        });
        window.open(decodeURIComponent(routerData.href), '_blank');
      }
      if (name === 'product_name' && this.$route.query.type === 'sku') {
        const req = {
          main_data: 't_product',
          fields: ['id'],
          conditions: [
            {
              field_name: 'product_code',
              type: 2,
              keywords: [row.product_code],
            },
          ],
          index: 1,
          page_size: 10,
        };
        SearchMainData(req)
          .then(res => {
            console.log(res);
            const spuId = res.data.map(item => JSON.parse(item));
            const routerData = this.$router.resolve({
              path: '/dashboard/drug-detail',
              query: { type: 'preview', source: 'spu', id: spuId[0].id },
            });
            window.open(decodeURIComponent(routerData.href), '_blank');
          })
          .catch(err => {
            this.$message.error(err.message);
          });
      }
    },
    // 跳转新建
    handleAddData() {
      const routerData = this.$router.resolve({
        path: '/dashboard/drug-detail',
        query: { type: 'new', source: this.$route.query.type },
      });
      window.open(decodeURIComponent(routerData.href), '_blank');
    },
    // 获取列表数据
    getList() {
      this.requestData = true;
      this.filterList = this.getStorage()[this.$route.query.type] || [];
      console.log(this.filterList);
      let req = {};
      if (this.filterList.length) {
        const arr = [];
        this.filterList.forEach(item => {
          arr.push({
            field_name: item.name,
            type: 1,
            keywords: [item.value],
          });
        });
        req = {
          main_data: this.$route.query.type === 'spu' ? 't_product' : 't_product_sku',
          fields: this.configList.map(item => item.property),
          conditions: arr,
          index: this.page,
          page_size: this.pageSize,
        };
      } else {
        req = {
          main_data: this.$route.query.type === 'spu' ? 't_product' : 't_product_sku',
          fields: this.configList.map(item => item.property),
          conditions: [],
          index: this.page,
          page_size: this.pageSize,
        };
      }
      req.fields.push('id');
      req.fields.push('product_code');

      SearchMainData(req)
        .then(res => {
          const dep = res.data.map(item => {
            return JSON.parse(item);
          });
          this.dealDataLabel(dep);
          this.editData = res.data.map(item => {
            return JSON.parse(item);
          });
          this.tableData = dep;
          //   console.log(this.tableData);
          this.total = res.total_num;
          this.requestData = false;
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    },
    // 获取配置列表
    getConfig() {
      if (this.$route.query.type === 'spu') {
        const preferConig = localStorage.getItem(`${this.$route.query.type}-prefer-config`);
        this.configList = preferConig
          ? JSON.parse(preferConig)
          : spuConfig.filter(item => item.default);
        this.labelConfig = spuLabelConfig;
      } else if (this.$route.query.type === 'sku') {
        const preferConig = localStorage.getItem(`${this.$route.query.type}-prefer-config`);
        this.configList = preferConig
          ? JSON.parse(preferConig)
          : skuConfig.filter(item => item.default);
        this.labelConfig = skuLabelConfig;
      } else {
        this.$message.error('未提供数据列表类型');
        this.$router.push('home');
      }
      if (this.role === 3) {
        const arr = ['source', 'comment', 'in_doubt', 'doubt_reason', 'qa_persons'];
        // 商务人员对清洗备注、部门归一字段不可见
        this.configList = this.configList.filter(ele => !arr.includes(ele.property));
      }

      const transProperty = [];
      this.configList.forEach(item => {
        this.configObj[item.property] = item;

        // 商务人员不能直接编辑,对系统字段不可见
        if (this.role === 3) item.edit = false;

        // 处理批量编辑的字段名称
        if (item.edit) {
          this.keyOpts.push({
            value: item.property,
            label: item.title,
            search: item.search,
          });
        }

        transProperty.push(item.property);
      });
      this.dealCutsomConfig(transProperty);
    },
    // 处理自定义字段左右默认展示
    dealCutsomConfig(transProperty) {
      if (!transProperty.length) return;
      if (this.$route.query.type === 'spu') {
        this.allDataFields = spuConfig.map(item => {
          return { label: item.title, key: item.property };
        });
        if (this.role === 3) {
          const arr = ['source', 'comment', 'in_doubt', 'doubt_reason', 'qa_persons'];
          // 商务人员对清洗备注字段不可见
          this.allDataFields = this.allDataFields.filter(ele => !arr.includes(ele.key));
        }
        this.transferedFields = transProperty;
        // console.log(this.allDataFields, this.transferedFields);
      }
      if (this.$route.query.type === 'sku') {
        this.allDataFields = skuConfig.map(item => {
          return { label: item.title, key: item.property };
        });
        if (this.role === 3) {
          const arr = ['source', 'comment', 'in_doubt', 'doubt_reason', 'qa_persons'];
          // 商务人员对清洗备注字段不可见
          this.allDataFields = this.allDataFields.filter(ele => !arr.includes(ele.key));
        }
        this.transferedFields = transProperty;
        // console.log(this.allDataFields, this.transferedFields);
      }
    },
    // 打开自定义字段设置弹窗
    handleSetConfig() {
      this.showTransferField = true;
      this.resetTransEle();
    },
    // 关闭设置列表字段transfer
    closeTransferField() {
      this.showTransferField = false;
      this.resetTransEle();
    },
    // 重置自定义字段穿梭框
    resetTransEle() {
      this.rightFieldCheck = [];
      this.$nextTick(() => {
        this.$refs.fieldTransfer.$children[0].$children[1].$el.scrollTop = 0;
        this.$refs.fieldTransfer.$children[3].$children[1].$el.scrollTop = 0;
        this.$refs.fieldTransfer.$children[0].checked = [];
        this.$refs.fieldTransfer.$children[3].checked = [];
      });
    },
    handleTransferChange(value, direction) {
      console.log(value, direction);
      if (direction === 'left') {
        this.rightFieldCheck = [];
      }
      if (direction === 'right') {
        let arr = spuConfig.map(item => {
          return { label: item.title, key: item.property };
        });
        if (this.$route.query.type === 'sku')
          arr = skuConfig.map(item => {
            return { label: item.title, key: item.property };
          });
        const arr1 = arr.map(ele => ele.key);
        // console.log(arr1);
        this.transferedFields.sort((x, y) => {
          return arr1.indexOf(x) - arr1.indexOf(y);
        });
      }
    },
    choosedRightField(val) {
      this.rightFieldCheck = val;
    },
    // 上移
    handleFieldUp() {
      if (this.rightFieldCheck.length === 1) {
        const idx = this.transferedFields.findIndex(item => item === this.rightFieldCheck[0]);
        // console.log(idx);
        if (idx === 0) {
          this.$message.warning('没有上移空间了');
          return;
        }
        // 取出上移字段
        const changeItem = JSON.parse(JSON.stringify(this.transferedFields[idx - 1]));
        // 删除字段
        this.transferedFields.splice(idx - 1, 1);
        // 插入字段
        this.transferedFields.splice(idx, 0, changeItem);
      } else {
        this.$message.error('一次只能移动一个字段');
      }
    },
    // 下移
    handleFieldDown() {
      if (this.rightFieldCheck.length === 1) {
        const idx = this.transferedFields.findIndex(item => item === this.rightFieldCheck[0]);
        // console.log(idx);
        if (idx === this.transferedFields.length - 1) {
          this.$message.warning('没有下移空间了');
          return;
        }
        // 取出下移字段
        const changeItem = JSON.parse(JSON.stringify(this.transferedFields[idx]));
        // 删除字段
        this.transferedFields.splice(idx, 1);
        // 插入字段
        this.transferedFields.splice(idx + 1, 0, changeItem);
      } else {
        this.$message.error('一次只能移动一个字段');
      }
    },
    // 恢复默认字段
    handleResetTransfer() {
      let config = [];
      if (this.$route.query.type === 'spu') {
        config = spuConfig.filter(item => item.default);
      } else if (this.$route.query.type === 'sku') {
        config = skuConfig.filter(item => item.default);
      }
      const transProperty = config.map(item => item.property);
      this.dealCutsomConfig(transProperty);
      this.resetTransEle();
    },
    // 应用自定义字段配置
    comfirmTransfer() {
      console.log(this.transferedFields);
      let newConfig = [];
      if (this.$route.query.type === 'spu') {
        newConfig = spuConfig.filter(item => this.transferedFields.includes(item.property));
      }
      if (this.$route.query.type === 'sku') {
        newConfig = skuConfig.filter(item => this.transferedFields.includes(item.property));
      }
      newConfig.sort((x, y) => {
        return (
          this.transferedFields.indexOf(x.property) - this.transferedFields.indexOf(y.property)
        );
      });

      // 以防重新渲染后视图更新不及时的问题
      this.configList = [];
      this.keyOpts = [];
      this.configObj = {};
      this.$nextTick(() => {
        this.configList = newConfig;
        this.configList.forEach(item => {
          this.configObj[item.property] = item;

          if (this.role === 3) item.edit = false;

          if (item.edit) {
            this.keyOpts.push({
              value: item.property,
              label: item.title,
              search: item.search,
            });
          }
        });
        localStorage.setItem(`${this.$route.query.type}-prefer-config`, JSON.stringify(newConfig));
        this.closeTransferField();
        this.getList();
      });
    },

    // 导出弹窗
    handleExport() {
      if (this.selectedData.length > 200) {
        this.$message.error('一次导出数据不能超过200条!');
      } else if (!this.selectedData.length) {
        this.showExportOpt = true;
        this.customExport();
      } else {
        this.showExportOpt = false;
        this.customExport();
      }
    },
    customExport() {
      this.showDataExportDialog = true;
      if (this.$route.query.type === 'spu') {
        this.title = '药品';
        this.dataExportForm.allExportFields = spuConfig.map(item => {
          return { label: item.title, key: item.property };
        });
      }
      if (this.$route.query.type === 'sku') {
        this.title = '药品(SKU)';
        this.dataExportForm.allExportFields = skuConfig.map(item => {
          return { label: item.title, key: item.property };
        });
      }
    },
    handleCloseDialog() {
      this.dataExportForm.quantity = 20;
      this.showDataExportDialog = false;
      this.dataExportForm.exportRightFields = [];
      this.dataExportForm.checkedFields = [];
      // 重置滚动条
      this.$nextTick(() => {
        this.$refs.exportFieldTransfer.$children[0].$children[1].$el.scrollTop = 0;
        this.$refs.exportFieldTransfer.$children[0].checked = [];
      });
    },
    // 导出字段上移
    handleExportFieldUp() {
      if (this.dataExportForm.checkedFields.length === 1) {
        const idx = this.dataExportForm.exportRightFields.findIndex(
          item => item === this.dataExportForm.checkedFields[0],
        );
        // console.log(idx);
        if (idx === 0) {
          this.$message.warning('没有上移空间了');
          return;
        }
        // 取出上移字段
        const changeItem = JSON.parse(
          JSON.stringify(this.dataExportForm.exportRightFields[idx - 1]),
        );
        // 删除字段
        this.dataExportForm.exportRightFields.splice(idx - 1, 1);
        // 插入字段
        this.dataExportForm.exportRightFields.splice(idx, 0, changeItem);
      } else {
        this.$message.error('一次只能移动一个字段');
      }
    },

    // 导出字段下移
    handleExportFieldDown() {
      if (this.dataExportForm.checkedFields.length === 1) {
        const idx = this.dataExportForm.exportRightFields.findIndex(
          item => item === this.dataExportForm.checkedFields[0],
        );
        // console.log(idx);
        if (idx === this.dataExportForm.exportRightFields.length - 1) {
          this.$message.warning('没有下移空间了');
          return;
        }
        // 取出下移字段
        const changeItem = JSON.parse(JSON.stringify(this.dataExportForm.exportRightFields[idx]));
        // 删除字段
        this.dataExportForm.exportRightFields.splice(idx, 1);
        // 插入字段
        this.dataExportForm.exportRightFields.splice(idx + 1, 0, changeItem);
      } else {
        this.$message.error('一次只能移动一个字段');
      }
    },
    // 自定义导出字段
    changeExportField(value, direction) {
      if (direction === 'left') {
        this.dataExportForm.checkedFields = [];
      }
    },
    exportCheckedFields(val) {
      this.dataExportForm.checkedFields = val;
    },
    // 确认导出
    comfirmExportData() {
      console.log(this.dataExportForm.exportRightFields);
    },
  },
};
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>

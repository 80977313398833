<template slot-scope="scope">
  <div class="table-cell" @mouseenter="switchShow" @mouseleave="switchShow">
    {{ scope.row[property] }}
    <el-popover
      v-if="hover && formType === 'select'"
      placement="bottom-end"
      width="200"
      @hide="close"
      trigger="click"
      v-model="visible"
    >
      <div class="table-popover">
        <!-- <div>{{ title }}</div> -->
        <el-select
          class="table-popover-input"
          v-model="val"
          placeholder="请选择"
          clearable
          filterable
          @change="hanldeSelectVal"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <div>
          <el-button type="primary" @click="edit">确定</el-button>
          <el-button class="table-edit-cell-cancelBtn" @click="visible = false">取消</el-button>
        </div>
      </div>
      <i slot="reference" class="table-edit el-icon-edit"></i>
    </el-popover>
    <el-popover
      v-if="hover && formType === 'input'"
      placement="bottom-end"
      width="200"
      @hide="close"
      trigger="click"
      v-model="visible"
    >
      <div class="table-popover">
        <!-- <div>{{ title }}</div> -->
        <el-input class="table-popover-input" placeholder="请输入" v-model="val"></el-input>
        <div>
          <el-button type="primary" @click="edit">确定</el-button>
          <el-button class="table-edit-cell-cancelBtn" @click="visible = false">取消</el-button>
        </div>
      </div>
      <i slot="reference" class="table-edit el-icon-edit"></i>
    </el-popover>
    <el-popover
      v-if="hover && formType === 'cascader'"
      placement="bottom-end"
      width="300"
      @hide="close"
      trigger="click"
      v-model="visible"
    >
      <div class="table-popover">
        <!-- <div>{{ title }}</div> -->
        <el-cascader
          ref="cascaderMallCatergory"
          class="table-popover-input"
          v-model="val"
          :props="data"
          @change="hanldeCascaderVal"
        ></el-cascader>
        <div>
          <el-button type="primary" @click="edit">确定</el-button>
          <el-button class="table-edit-cell-cancelBtn" @click="visible = false">取消</el-button>
        </div>
      </div>
      <i slot="reference" class="table-edit el-icon-edit"></i>
    </el-popover>
  </div>
</template>

<script>
export default {
  name: 'TableEditCell',
  components: {},
  props: {
    // 表单使用文本输入input还是下拉选择select 还需要支持行政区划的cascader级联选择器
    formType: {
      type: String,
      default: 'select',
    },
    // 该单元格操作的属性
    property: {
      type: String,
      required: true,
    },
    // 显示的表单标题
    title: {
      type: String,
      default: '',
    },
    // 当前的scope
    scope: {
      type: Object,
      default: () => {},
    },
    data: {
      type: Object,
      default: () => {},
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      val: '',
      visible: false,
      // 示例用下拉选项 需要用参数更换为对应属性的下拉列表
      // options: [
      //   {
      //     value: 1,
      //     label: 1,
      //   },
      //   {
      //     value: 2,
      //     label: 2,
      //   },
      // ],
      cascaderLabelList: [], // 级联的结果，label值
      selectLabel: '', // 下拉选项的labeL值
      paramLabel: null,
      hover: false,
    };
  },
  computed: {},
  mounted() {
    if (this.formType === 'cascader') {
      this.val = [];
    }
  },
  methods: {
    hanldeCascaderVal() {
      const label = this.$refs.cascaderMallCatergory.getCheckedNodes()[0].pathLabels;
      this.cascaderLabelList = label;
    },
    hanldeSelectVal(val) {
      if (val) {
        const { label } = this.options.find(item => {
          return item.value === val;
        });
        // console.log(label);
        this.selectLabel = label;
      }
    },

    edit() {
      console.log(this.val, this.scope.row[this.property]);
      if (this.val !== this.scope.row[this.property]) {
        // 修改了哪个id的数据的哪个属性 修改后的结果是什么
        if (this.formType === 'cascader') {
          this.paramLabel = this.cascaderLabelList;
        } else if (this.formType === 'select') {
          this.paramLabel = this.selectLabel;
        }

        this.$emit('edit', this.scope.row.id, this.property, this.val, this.paramLabel);
      } else {
        this.$message.error('数据未发生改变');
      }
      this.visible = false;
    },
    switchShow() {
      if (!this.hover) {
        this.hover = true;
      } else if (!this.visible) {
        this.hover = false;
      }
    },
    close() {
      if (this.formType === 'cascader') {
        this.val = [];
      } else {
        this.val = '';
      }

      this.hover = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import './table-edit-cell.scss';
</style>
